html {
  scroll-behavior: smooth;
  height: 100%;
}

body{
  height: 100%
}

#root{
  height: 100%
}

.App-header {
  font-size: calc(10px + 2vmin);
  color: black;
}

.Button-Color{
  background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
  border-Radius: 3;
  border: 0;
  color: white;
  height: 48;
  padding: 0 30px;
  box-Shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
}

.Spacing{
    padding: 2%;
    text-align: center;
}

a {
  color: inherit;
  text-decoration: none;
}